import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";

export default {
  type: FilterTypes.Contact,
  exampleView: {
    component: () => import('../exampleView.vue'),
    propsData: {
      filterKey: 'Contact',
      imgExamples: [ require('./Contact.png') ]
    }
  },
  icon: {
    name: "contacts",
    color: "rgba(var(--a-dark), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/Contact/ContactView.vue'),
} as FilterConfig
