import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";

export default {
  type: FilterTypes.Files,
  exampleView: {
    component: () => import('../exampleView.vue'),
    propsData: {
      filterKey: 'Files',
      imgExamples: [ require('./Files.png') ]
    }
  },
  icon: {
    name: "insert_drive_file",
    color: "rgba(var(--a-primary), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/Files/FilesView.vue'),
  advancedFieldNames: [
    'files_extension_list',
    'files_max_file_size',
    'files_mime_type_list',
    'files_min_file_size',
  ]
} as FilterConfig
