import Vue, { AsyncComponent, ComponentOptions } from 'vue'

export type FilterIcon = {
  name: string
  color: string
}

type AdvancedFieldName = string

export type FilterConfig = {
  type: FilterTypes
  icon: FilterIcon
  view: ComponentOptions<Vue> | typeof Vue | AsyncComponent
  advancedFieldNames?: Array<AdvancedFieldName>
  advancedTabFields?: Array<AdvancedFieldName>
  exampleView?: {
    component: ComponentOptions<Vue> | typeof Vue | AsyncComponent
    propsData: {
      filterKey: string
      textExamples?: Array<string>
      imgExamples?: Array<string>
    }
  }
  description?: string
}

export enum ExtraSettingsMenuItems {
  AdvancedSettings = 'advanced_settings',
  VirtualNew = 'virtual_new',
  CommonRules = 'common_rules',
}

export enum FilterTypes {
  StickerPack = "sticker_pack",
  StopWord = "stop_word",
  Flood = "flood",
  SelfLinks = "self_links",
  EmailLinks = "email_links",
  Sticker = "sticker",
  Voice = "voice",
  Images = "images",
  Gif = "gif",
  Audio = "audio",
  Video = "video",
  Files = "files",
  Contact = "contact",
  Warn = "warn",
  RtlMessage = "rtl_message",
  CapsMessage = "caps_message",
  BotReply = "bot_reply",
  BotCommand = "bot_command",
  ChannelReply = "channel_reply",
  ChatReply = "chat_reply",
  AnyMessage = "any_message",
  PermittedWord = "permitted_word",
  RegularExpression = "regular_expression",
  VideoNote = "video_note",
  HieroglyphMessage = "hieroglyph_message",
  MessageLength = "message_length",
  Dice = "dice",
  Referral = "referral",
  CensorWord = "censor_word",
  WebLinks = "web_links",
  ChannelMessage = "channel_message",
  Spoiler = "spoiler",
  Emoji = "emoji",
  Story = "story",
  Location = "Location"
}

export enum CommonSettingFields {
  Filter_from_to = 'filter_from_to',
  Resolve_from_rank = 'resolve_from_rank',
  Take_away_reputation = 'take_away_reputation',
  Take_away_xp = 'take_away_xp',
  Comments_mode = 'comments_mode',
  Ban_time_multiplier = 'ban_time_multiplier'
}

export enum CommonRulesFields {
  Remove = 'remove',
  Remove_timeout = 'remove_timeout',
  Notice_before_ban_count = 'notice_before_ban_count',
  Warning_message = 'warning_message',
  Ban_type = 'ban_type',
  Ban_notice = 'ban_notice',
  Ban_message = 'ban_message',
  EnabledCommonFilter = 'enabled_common_filter',
  Limit = 'limit',
  Limit_interval = 'limit_interval',
  Round_limit_time = 'round_limit_time',
}

export enum VirtualNewFields {
  Remove_edited = 'remove_edited',
  Remove_edited_timeout = 'remove_edited_timeout',
  Remove_edited_msg = 'remove_edited_msg',
  First_message_remove = 'first_message_remove',
  First_message_ban_type = 'first_message_ban_type',
  New_limit = 'new_limit',
  New_limit_msg_count = 'new_limit_msg_count',
}

export enum BaseSpecialSettingFields {
  Ignore_caption = 'ignore_caption',
  Strict_mode = 'strict_mode',
  Handle_modified = 'handle_modified',
  Handle_user_bio = 'handle_user_bio',
  Bot_commands_whitelist = 'bot_commands_whitelist',
  Stop_words = 'stop_words',
  Bots_for_reply_whitelist = 'bots_for_reply_whitelist',
  Bot_reply_by_user = 'bot_reply_by_user',
  Bot_reply_by_via = 'bot_reply_by_via'
}

export enum StrictModeFields {
  Strict_mode = 'strict_mode',
  Custom_dict = 'custom_dict'
}
