import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";
import i18n from "@/i18n/i18n";

export default {
  type: FilterTypes.MessageLength,
  description: i18n.t('message_length_filter_example_view_text').toString(),
  icon: {
    name: "linear_scale",
    color: "rgba(var(--a-primary), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/MessageLength/MessageLengthView.vue'),
  advancedTabFields: [
    'message_length_max',
    'message_length_max_lines',
    'message_length_min',
    'message_length_min_lines',
    'message_length_words_count_max',
    'message_length_words_count_min',
  ]
} as FilterConfig
