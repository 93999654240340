import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";
import i18n from "@/i18n/i18n";

export default {
  type: FilterTypes.StopWord,
  description: i18n.t('stop_words_filter_example_view_text').toString(),
  icon: {
    name: "remove_circle",
    color: "rgba(var(--a-danger), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/StopWord/StopWordView.vue'),
  advancedFieldNames: [
    'stop_words_search_in_usernames',
    'stop_words',
    'stop_word_handle_modified',
    'stop_word_strict_mode',
  ],

} as FilterConfig
