import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";

export default {
  type: FilterTypes.EmailLinks,
  exampleView: {
    component: () => import('../exampleView.vue'),
    propsData: {
      filterKey: 'EmailLinks',
      textExamples: [
        'example.email@gmail.com',
        'another-email@yahoo.com',
        'email.example@mail.ru'
      ]
    }
  },
  icon: {
    name: "email",
    color: "rgba(var(--a-dark), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/EmailLinks/EmailLinksView.vue')
} as FilterConfig
