import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";
import i18n from "@/i18n/i18n";

export default {
  type: FilterTypes.Flood,
  description: i18n.t('flood_filter_example_view_text').toString(),
  icon: {
    name: "speaker_notes_off",
    color: "rgba(var(--a-warning), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/Flood/FloodView.vue'),
  advancedTabFields: [
    'flood_duplicate_words_multiply',
    'flood_message_count',
    'flood_message_interval',
    'flood_min_message_interval',
    'flood_remove_if_prev_global_animation',
    'flood_remove_if_prev_global_audio',
    'flood_remove_if_prev_global_contact',
    'flood_remove_if_prev_global_dice',
    'flood_remove_if_prev_global_document',
    'flood_remove_if_prev_global_photo',
    'flood_remove_if_prev_global_poll',
    'flood_remove_if_prev_global_sticker',
    'flood_remove_if_prev_global_text',
    'flood_remove_if_prev_global_video',
    'flood_remove_if_prev_global_videonote',
    'flood_remove_if_prev_global_voice',
    'flood_remove_if_prev_user_animation',
    'flood_remove_if_prev_user_audio',
    'flood_remove_if_prev_user_contact',
    'flood_remove_if_prev_user_dice',
    'flood_remove_if_prev_user_document',
    'flood_remove_if_prev_user_photo',
    'flood_remove_if_prev_user_poll',
    'flood_remove_if_prev_user_sticker',
    'flood_remove_if_prev_user_text',
    'flood_remove_if_prev_user_video',
    'flood_remove_if_prev_user_videonote',
    'flood_remove_if_prev_user_voice',
  ]
} as FilterConfig
