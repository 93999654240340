












































































































































































































































































import ChatLicense from '@/includes/logic/ChatLicense'
import { InputSetups } from '@/mixins/input-setups'
import RecurringInvoicesMixin from "@/includes/logic/RecurringInvoices/RecurringInvoicesMixin";
import DashboardMigrateCard from "@/components/CommunityDashboard/DashboardMigrateCard.vue";
import { getNetworkTitle } from "../../includes/logic/Networks/utils";
import { removeGroupFromNetwork } from "@/includes/logic/Networks/logic";
import { BaseNetworkData } from "@/includes/types/networks";
import NetworksHelperMixin from "@/includes/logic/Networks/NetworksHelperMixin";

import { atSignedLogin } from "piramis-base-components/src/shared/utils/tgLoginHelpers";
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { timezoneList } from 'piramis-base-components/src/logic/timizone-list'
import LanguagePicker from 'piramis-base-components/src/components/LanguagePicker.vue'
import ClipboardCopyMixin from 'piramis-base-components/src/logic/helpers/ClipboardCopyMixin'
import OurProducts from 'piramis-base-components/src/plugins/AdminTemplate/components/OurProducts/OurProducts.vue'
import AvatarsRow from 'piramis-base-components/src/components/AvatarsRow.vue';
import RecurringTargets from 'piramis-base-components/src/components/RecurringTargets.vue';
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue';

import { Component, Mixins, Prop } from 'vue-property-decorator'
import { Location } from 'vue-router'
import { upperFirst } from 'lodash'
import moment from 'moment'

@Component({
  methods: {
    getNetworkTitle,
    atSignedLogin,
    moment
  },
  components: {
    Icon,
    DashboardMigrateCard,
    LanguagePicker,
    OurProducts,
    AvatarsRow,
    RecurringTargets,
  },
  data() {
    return {
      timezoneList
    }
  }
})
export default class DashboardChatInfo extends Mixins(UseFields, InputSetups, ClipboardCopyMixin, RecurringInvoicesMixin, NetworksHelperMixin) {
  @Prop({ type: Boolean, required: true }) isConfigDeprecated!: boolean

  chatLicense: ChatLicense = new ChatLicense(this.chat)

  buyLink: Location = {
    'name': 'buy',
  }

  get targetInvoicePositions() {
    if (this.invoice) {
      return this.invoice.targets
    }

    return []
  }

  get piramisSpamDetectionModes(): Array<SelectOptionData> {
    return [
      { 'label': this.$t('piramis_spam_detection_modes_none').toString(), 'value': 'None' },
      { 'label': this.$t('piramis_spam_detection_modes_log').toString(), 'value': 'Log' },
      { 'label': this.$t('piramis_spam_detection_modes_mark').toString(), 'value': 'Mark' },
      { 'label': this.$t('piramis_spam_detection_modes_ban').toString(), 'value': 'Ban' },
    ]
  }

  get showMigrateCard() {
    if (this.isConfigDeprecated) {
      return this.needMigrate
    } else {
      return true
    }
  }

  get chat() {
    return this.$store.state.chatState.chat
  }

  get needMigrate() {
    if (this.chat) {
      return this.chat.need_migrate
    }

    return false
  }

  get journalButtonTitle():string {
    return this.chat!.log_channel_id ? this.$t('dellog_journal').toString() : this.$t('log_journal').toString()
  }

  get buyButtonType():string {
    if (this.chatLicense.isLicenseExpired || this.chatLicense.expiresSoon) {
      return 'danger'
    }

    return 'default'
  }

  get buyLicenseButtonIcon() {
    if (this.invoice && !this.chatLicense.isLicenseExpired) {
      return 'close'
    } else {
      return 'shopping-cart'
    }
  }

  get buyLicenseButtonTitle():string {
    if (this.chatLicense.isLicenseExpired) {
      return this.$t('button_license_buy').toString()
    } else {
      if (this.invoice && !this.chatLicense.isLicenseExpired) {
        return this.$t('recurrent_license_active_auto_pay_cancel').toString()
      } else {
        return this.$t('button_license_prolongate').toString()
      }
    }
  }

  get currentLicenseStatus():string {
    if (!this.chatLicense.isLicenseExpired) {
      if (this.invoice && !this.chatLicense.isLicenseExpired) {
        return this.$t('license_subscription_status_active', [ upperFirst(this.chatLicense.licenseType) ]).toString()
      } else {
        return this.$t('license_status_active', [ upperFirst(this.chatLicense.licenseType) ]).toString()
      }
    }

    if (this.chatLicense.licenseExpiresAt === '') {
      return this.$t('license_status_not_exists').toString()
    }

    return this.$t('license_status_expired').toString()
  }

  removeGroupFromNetworkVm(netId: BaseNetworkData['id']) {
    if (this.chat) {
      removeGroupFromNetwork(this.chat.chat_id, netId)
        .then(res => {

          if (res) {
            this.$store.dispatch('updateChatState', res)
          }
        })
        .finally(() => {
          this.$baseTemplate.loader.close()
        })
    }
  }

  get psd() {
    return this.$store.state.chatState.chat!.psd
  }

  set psd(value: string) {
    this.$store.dispatch('saveChatConfig', { psd: value })
  }

  get timezone() {
    if (this.isConfigDeprecated) {
      return this.stateChatPath?.timezone
    } else {
      return this.$store.state.chatState.chat!.timezone
    }
  }

  set timezone(value: string) {
    if (this.isConfigDeprecated) {
      this.stateChatPath!.timezone = value
    } else {
      this.$store.dispatch('saveChatConfig', { timezone: value })
    }
  }

  handleBuyButtonClick() {
    if (this.invoice && !this.chatLicense.isLicenseExpired) {
      this.deleteRecurringInvoice()
    } else {
      this.$router.push(this.buyLink)
    }
  }

  handleLocaleChange(locale: 'ru' | 'en'): void {
    //TODO remove chat type check later
    if (this.isConfigDeprecated) {
      this.$store.commit('updateChatLang', locale)
    } else {
      if (locale !== this.chat!.language_code) {
        this.$store.dispatch('saveChatConfig', { language_code: locale })
      }
    }
  }

  journalButtonClick():void {
    if (this.chat.log_channel_id) {
      this.$store.dispatch('runChatAction', 'resetlogchannel')
    } else {
      if (this.isConfigDeprecated) {
        this.$router.push({ name: 'journal' })
      } else {
        this.$router.push({ name: 'chat_common_settings', params: { tab: 'journal' } })
      }
    }
  }

  goToChatCatalogSettings(): void {
    this.$router.push({
      name: 'catalog_integration'
    })
  }
}
