import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";

export default {
  type: FilterTypes.Story,
  exampleView: {
    component: () => import('../exampleView.vue'),
    propsData: {
      filterKey: 'Story',
      imgExamples: [ require('./Story.png') ]
    }
  },
  icon: {
    name: "camera_front",
    color: "rgba(var(--a-danger), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/Story/StoryView.vue'),
} as FilterConfig
