import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";

export default {
  type: FilterTypes.WebLinks,
  exampleView: {
    component: () => import('../exampleView.vue'),
    propsData: {
      filterKey: 'WebLinks',
      textExamples: [ 'https://www.google.com', 'yandex.ru', 'youtube.com' ]
    }
  },
  icon: {
    name: "insert_link",
    color: "rgba(var(--a-dark), 1)",
    size: '50px'
  },
  view: () => import('@/views/chat/filters/Filters/WebLinks/WebLinksView.vue'),
  advancedFieldNames: [
    'web_links_dont_check_ips',
    'web_links_strict_mode',
    'accept_http_links_whitelist',
  ],
  advancedTabFields: [
    'accept_http_links_from_top_100',
    'accept_http_links_from_top_ru_100',
    'web_links_dont_check_ips'
  ]
} as FilterConfig
