import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";
import i18n from "@/i18n/i18n";

export default {
  type: FilterTypes.CapsMessage,
  description: i18n.t('caps_message_filter_example_view_text').toString(),
  icon: {
    name: "format_size",
    color: "rgba(var(--a-danger), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/CapsMessage/CapsMessageView.vue'),
  advancedFieldNames: [
    'caps_message_strict_mode',
    'caps_message_whitelist'
  ]
} as FilterConfig
