import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";
import i18n from "@/i18n/i18n";

export default {
  type: FilterTypes.Audio,
  description: i18n.t('audio_message_filter_example_view_text').toString(),
  icon: {
    name: "audiotrack",
    color: "rgba(var(--a-primary), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/Audio/AudioView.vue'),
} as FilterConfig
