import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";
import i18n from "@/i18n/i18n";

export default {
  type: FilterTypes.Emoji,
  description: i18n.t('emoji_filter_example_view_text').toString(),
  icon: {
    name: "emoji_emotions",
    color: "rgba(var(--a-primary), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/Emoji/EmojiView.vue'),
  advancedFieldNames: [
    'emoji_any_message_max_smiles',
    'emoji_only_smiles_message_max',
  ]
} as FilterConfig
