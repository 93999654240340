import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";

export default {
  type: FilterTypes.Spoiler,
  exampleView: {
    component: () => import('../exampleView.vue'),
    propsData: {
      filterKey: 'Spoiler',
      imgExamples: [ require('./Spoiler.png') ]
    }
  },
  icon: {
    name: "auto_awesome",
    color: "rgba(var(--a-primary), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/Spoiler/SpoilerView.vue'),
  advancedFieldNames: [
    'spoiler_allow_message_begin',
    'spoiler_allow_message_end',
    'spoiler_max_size',
    'spoiler_min_message_len',
    'spoiler_min_words_count',
  ]
} as FilterConfig
