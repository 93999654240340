import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";

export default {
  type: FilterTypes.RtlMessage,
  exampleView: {
    component: () => import('../exampleView.vue'),
    propsData: {
      filterKey: 'RtlMessage',
      imgExamples: [ require('./RtlMessage.png') ]
    }
  },
  icon: {
    name: "format_textdirection_r_to_l",
    color: "rgba(var(--a-danger), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/RtlMessage/RtlMessageView.vue'),
  advancedFieldNames: [ 'rtl_message_max_percent' ]
} as FilterConfig
