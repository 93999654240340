import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";
import i18n from "@/i18n/i18n";

export default {
  type: FilterTypes.CensorWord,
  description: i18n.t('censor_word_filter_example_view_text').toString(),
  icon: {
    name: "announcement",
    color: "rgba(var(--a-danger), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/CensorWord/CensorWordView.vue'),
  advancedFieldNames: [
    'censor_word_custom_dict',
    'censor_word_strict_mode',
    'censor_word_use_custom_dict',
  ],
  advancedTabFields: [
    'censor_word_fuck',
    'censor_word_govno',
    'censor_word_jopa',
    'censor_word_mudak',

  ]
} as FilterConfig
