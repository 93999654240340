import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";

export default {
  type: FilterTypes.ChannelReply,
  exampleView: {
    component: () => import('../exampleView.vue'),
    propsData: {
      filterKey: 'ChannelReply',
      imgExamples: [ require('./ChannelReply.png') ]
    }
  },
  icon: {
    name: "forum",
    color: "rgba(var(--a-primary), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/ChannelReply/ChannelReplyView.vue'),
  advancedFieldNames: [
    'channels_whitelist'
  ]
} as FilterConfig
