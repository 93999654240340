import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";

export default {
  type: FilterTypes.Dice,
  exampleView: {
    component: () => import('../exampleView.vue'),
    propsData: {
      filterKey: 'Dice',
      imgExamples: [ require('./Dice.png') ]
    }
  },
  icon: {
    name: "casino",
    color: "rgba(var(--a-dark), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/Dice/DiceView.vue')
} as FilterConfig
