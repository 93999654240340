import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";

export default {
  type: FilterTypes.RegularExpression,
  exampleView: {
    component: () => import('../exampleView.vue'),
    propsData: {
      filterKey: 'RegularExpression',
      textExamples: [
        '^((8|\\+7)[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{7,10}$',
        '^((([0-9A-Za-z]{1}[-0-9A-z\\.]{1,}[0-9A-Za-z]{1})|([0-9А-Яа-я]{1}[-0-9А-я\\.]{1,}[0-9А-Яа-я]{1}))@([-A-Za-z]{1,}\\.){1,2}[-A-Za-z]{2,})$',
        '\\d{1,3}(?=(\\d{3})+(?!\\d))'
      ]
    }
  },
  icon: {
    name: "spellcheck",
    color: "rgba(var(--a-danger), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/RegularExpression/RegularExpressionView.vue'),
  advancedFieldNames: [
    'regular_expression_strict_mode',
    'regular_expressions'
  ]
} as FilterConfig
