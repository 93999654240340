import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";

export default {
  type: FilterTypes.BotReply,
  exampleView: {
    component: () => import('../exampleView.vue'),
    propsData: {
      filterKey: 'BotReply',
      imgExamples: [ require('./BotReply.png') ]
    }
  },
  icon: {
    name: "android",
    color: "rgba(var(--a-primary), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/BotReply/BotReplyView.vue'),
  advancedFieldNames: [
    'bots_for_reply_whitelist',
    'bot_reply_by_user',
    'bot_reply_by_via',
  ]
} as FilterConfig
