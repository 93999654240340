import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";
import i18n from "@/i18n/i18n";

export default {
  type: FilterTypes.AnyMessage,
  description: i18n.t('any_message_filter_example_view_text').toString(),
  icon: {
    name: "surround_sound",
    color: "rgba(var(--a-primary), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/AnyMessage/AnyMessageView.vue'),
  advancedFieldNames: [
      'any_message_handler_type'
  ]
} as FilterConfig
