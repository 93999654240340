import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";
import i18n from "@/i18n/i18n";

export default {
  type: FilterTypes.ChannelMessage,
  description: i18n.t('channel_message_filter_example_view_text').toString(),
  icon: {
    name: "sensors",
    color: "rgba(var(--a-primary), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/ChannelMessage/ChannelMessageView.vue'),
  advancedFieldNames: [
    'channel_message_whitelist'
  ]
} as FilterConfig
