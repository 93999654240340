import StopWord from "@/views/chat/filters/Filters/StopWord/index";
import WebLinks from "@/views/chat/filters/Filters/WebLinks/index";
import SelfLinks from "@/views/chat/filters/Filters/SelfLinks/index";
import EmailLinks from "@/views/chat/filters/Filters/EmailLinks/index";
import RtlMessage from "@/views/chat/filters/Filters/RtlMessage/index";
import HieroglyphMessage from "@/views/chat/filters/Filters/HieroglyphMessage/index";
import CapsMessage from "@/views/chat/filters/Filters/CapsMessage/index";
import BotReply from "@/views/chat/filters/Filters/BotReply/index";
import BotCommand from "@/views/chat/filters/Filters/BotCommand/index";
import ChannelReply from "@/views/chat/filters/Filters/ChannelReply/index";
import ChatReply from "@/views/chat/filters/Filters/ChatReply/index";
import PermittedWord from "@/views/chat/filters/Filters/PermittedWord/index";
import RegularExpression from "@/views/chat/filters/Filters/RegularExpression/index";
import CensorWord from "@/views/chat/filters/Filters/CensorWord/index";
import MessageLength from "@/views/chat/filters/Filters/MessageLength/index";
import Referral from "@/views/chat/filters/Filters/Referral/index";
import Emoji from "@/views/chat/filters/Filters/Emoji/index";
import ChannelMessage from "@/views/chat/filters/Filters/ChannelMessage/index";
import Sticker from "@/views/chat/filters/Filters/Sticker/index";
import Voice from "@/views/chat/filters/Filters/Voice/index";
import Images from "@/views/chat/filters/Filters/Images/index";
import Gif from "@/views/chat/filters/Filters/Gif/index";
import StickerPack from "@/views/chat/filters/Filters/StickerPack/index";
import Audio from "@/views/chat/filters/Filters/Audio/index";
import Video from "@/views/chat/filters/Filters/Video/index";
import VideoNote from "@/views/chat/filters/Filters/VideoNote/index";
import Files from "@/views/chat/filters/Filters/Files/index";
import Contact from "@/views/chat/filters/Filters/Contact/index";
import Flood from "@/views/chat/filters/Filters/Flood/index";
import AnyMessage from "@/views/chat/filters/Filters/AnyMessage/index";
import Dice from "@/views/chat/filters/Filters/Dice/index";
import Spoiler from "@/views/chat/filters/Filters/Spoiler/index";
import Warn from "@/views/chat/filters/Filters/Warn/index";
import Location from "@/views/chat/filters/Filters/Location";
import Story from "./Story";

import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";

export default class FilterFabric {

  static create(type: FilterTypes): FilterConfig {
    if (type === FilterTypes.StopWord) {
      return StopWord
    }
    if (type === FilterTypes.StickerPack) {
      return StickerPack;
    }
    if (type === FilterTypes.WebLinks) {
      return WebLinks;
    }
    if (type === FilterTypes.SelfLinks) {
      return SelfLinks;
    }
    if (type === FilterTypes.EmailLinks) {
      return EmailLinks;
    }
    if (type === FilterTypes.RtlMessage) {
      return RtlMessage;
    }
    if (type === FilterTypes.HieroglyphMessage) {
      return HieroglyphMessage;
    }
    if (type === FilterTypes.CapsMessage) {
      return CapsMessage;
    }
    if (type === FilterTypes.BotReply) {
      return BotReply
    }
    if (type === FilterTypes.BotCommand) {
      return BotCommand;
    }
    if (type === FilterTypes.ChannelReply) {
      return ChannelReply;
    }
    if (type === FilterTypes.ChatReply) {
      return ChatReply;
    }
    if (type === FilterTypes.PermittedWord) {
      return PermittedWord
    }
    if (type === FilterTypes.RegularExpression) {
      return RegularExpression
    }
    if (type === FilterTypes.CensorWord) {
      return CensorWord
    }
    if (type === FilterTypes.MessageLength) {
      return MessageLength
    }
    if (type === FilterTypes.Referral) {
      return Referral
    }
    if (type === FilterTypes.Emoji) {
      return Emoji
    }
    if (type === FilterTypes.ChannelMessage) {
      return ChannelMessage
    }
    if (type === FilterTypes.Sticker) {
      return Sticker
    }
    if (type === FilterTypes.Voice) {
      return Voice
    }
    if (type === FilterTypes.Images) {
      return Images
    }
    if (type === FilterTypes.Gif) {
      return Gif
    }
    if (type === FilterTypes.Audio) {
      return Audio
    }
    if (type === FilterTypes.Video) {
      return Video
    }
    if (type === FilterTypes.VideoNote) {
      return VideoNote
    }
    if (type === FilterTypes.Files) {
      return Files
    }
    if (type === FilterTypes.Contact) {
      return Contact
    }
    if (type === FilterTypes.Flood) {
      return Flood
    }
    if (type === FilterTypes.AnyMessage) {
      return AnyMessage
    }
    if (type === FilterTypes.Dice) {
      return Dice
    }
    if (type === FilterTypes.Spoiler) {
      return Spoiler
    }
    if (type === FilterTypes.Warn) {
      return Warn
    }
    if (type === FilterTypes.Story) {
      return Story
    }
    if (type === FilterTypes.Location) {
      return Location
    }

    throw new Error('Unknown filter type')
  }
}
