import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";

export default {
  type: FilterTypes.Video,
  exampleView: {
    component: () => import('../exampleView.vue'),
    propsData: {
      filterKey: 'VideoNote',
      imgExamples: [ require('./Video.png') ]
    }
  },
  icon: {
    name: "videocam",
    color: "rgba(var(--a-primary), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/Video/VideoView.vue'),
  advancedTabFields: [
    'video_max_duaration',
    'video_max_file_size',
    'video_max_height',
    'video_max_width',
    'video_min_duaration',
    'video_min_file_size',
    'video_min_height',
    'video_min_width',
  ]
} as FilterConfig
