import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";

export default {
  type: FilterTypes.SelfLinks,
  exampleView: {
    component: () => import('../exampleView.vue'),
    propsData: {
      filterKey: 'SelfLinks',
      textExamples: [
        '@ChatKeeperBot',
        't.me/ChatKeeperBot',
        'https://t.me/ChatKeeperBot'
      ]
    }
  },
  icon: {
    name: "near_me",
    color: "rgba(var(--a-primary), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/SelfLinks/SelfLinksView.vue'),
  advancedFieldNames: [
    'self_links_dont_use_bots',
    'self_links_strict_mode',
    'self_links_dont_use_channels',
    'self_links_dont_use_chats',
    'self_links_dont_use_users',
    'tg_links_whitelist',
    'self_links_accept_mention_users'
  ],
  advancedTabFields: [
    'self_links_dont_use_dog',
    'self_links_dont_use_join',
    'self_links_dont_use_tme',
  ]
} as FilterConfig
