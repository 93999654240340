import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";

export default {
  type: FilterTypes.Warn,
  icon: {
    name: "remove_circle",
    color: "rgba(var(--a-danger), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/Warn/WarnView.vue')
} as FilterConfig
