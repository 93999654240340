import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";
import i18n from "@/i18n/i18n";

export default {
  type: FilterTypes.Images,
  description: i18n.t('images_message_filter_example_view_text').toString(),
  icon: {
    name: "photo",
    color: "rgba(var(--a-success), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/Images/ImagesView.vue'),
  advancedTabFields: [
    'images_max_file_size',
    'images_max_height',
    'images_max_width',
    'images_min_file_size',
    'images_min_height',
    'images_min_width',
  ]
} as FilterConfig
