import { FilterConfig, FilterTypes } from "@/views/chat/filters/FilterTypes";
import i18n from "@/i18n/i18n";

export default {
  type: FilterTypes.Referral,
  description: i18n.t('referral_filter_example_view_text').toString(),
  icon: {
    name: "how_to_reg",
    color: "rgba(var(--a-dark), 1)"
  },
  view: () => import('@/views/chat/filters/Filters/Referral/ReferralView.vue'),
  advancedFieldNames: [
      'referral_codes',
      'referral_sources',
  ]
} as FilterConfig
